.floating-whatsapp {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 40px;
    right: 40px;

    color: #fff;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 3px green;
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.3s ease;
    background-color: #03D693;
  }
  
  .fab {
    margin-top: 16px;
  }
  
  @media (max-width: 600px) {
    .floating-whatsapp {
      width: 50px;
      height: 50px;
      bottom: 20px;
      right: 20px;
      font-size: 24px;
    }
  
    svg {
      margin-top: 0px;
    }
  }