@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&family=Poppins:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Poppins';
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Montserrat';
}

.hero {
  background: url(./images/hero-bg.svg) top center no-repeat;
  background-size: cover;
}

.footer {
  /* background: url(./images/footer.svg) top center no-repeat; */
  background-color: #F3F4F5;
  background-size: cover;
}

.cta {
  background: linear-gradient(rgba(2,2,2,.7),rgba(0,0,0,.7)),url(./images/bg3.png) fixed center center;
}